import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_i8AMfKeYnY from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_AOuQ1DYzjk from "/vercel/path0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import vuetify_icons_RMzWu406ID from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import vue_datepicker_dyozMmv23j from "/vercel/path0/plugins/vue-datepicker.ts";
import vue_draggable_OKRqLUiGtd from "/vercel/path0/plugins/vue-draggable.ts";
import vuetify_OdPEdOST6f from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_i8AMfKeYnY,
  plugin_AOuQ1DYzjk,
  plugin_w2VlvAEcpa,
  vuetify_icons_RMzWu406ID,
  vuetify_no_client_hints_wMKVLl9fyU,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  chunk_reload_client_UciE0i6zes,
  hotjar_pBWYs5JQbk,
  vue_datepicker_dyozMmv23j,
  vue_draggable_OKRqLUiGtd,
  vuetify_OdPEdOST6f
]