import * as labsComponents from 'vuetify/labs/components'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"defaults":{"VAppBar":{"elevation":0},"VSheet":{"elevation":10,"width":300,"class":"pa-4","rounded":"xl"},"VBtn":{"rounded":"xl","elevation":0},"VTextField":{"rounded":"xl","variant":"outlined","density":"compact","hideDetails":"auto"},"VTextarea":{"rounded":"xl","variant":"outlined"},"VListItem":{"rounded":"xl"},"VList":{"rounded":"xl"},"VMenu":{"rounded":"xl"},"VCard":{"rounded":"xl"}},"theme":{"themes":{"light":{"colors":{"secondary":"#FFFFFF"}},"dark":{"colors":{"secondary":"#000000"}}}},"ssr":true}')
  
  
  options.components = {...labsComponents}
  
  return options
}

