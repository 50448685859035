import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_939rArDAEosqMeta } from "/vercel/path0/pages/list/[id].vue?macro=true";
import { default as listsIm5cAseXMXMeta } from "/vercel/path0/pages/lists.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as _91id_93YjTnfCyYZ0Meta } from "/vercel/path0/pages/todo/[id].vue?macro=true";
export default [
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939rArDAEosqMeta?.name ?? "list-id",
    path: _91id_939rArDAEosqMeta?.path ?? "/list/:id()",
    meta: _91id_939rArDAEosqMeta || {},
    alias: _91id_939rArDAEosqMeta?.alias || [],
    redirect: _91id_939rArDAEosqMeta?.redirect,
    component: () => import("/vercel/path0/pages/list/[id].vue").then(m => m.default || m)
  },
  {
    name: listsIm5cAseXMXMeta?.name ?? "lists",
    path: listsIm5cAseXMXMeta?.path ?? "/lists",
    meta: listsIm5cAseXMXMeta || {},
    alias: listsIm5cAseXMXMeta?.alias || [],
    redirect: listsIm5cAseXMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? "search",
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? "settings",
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YjTnfCyYZ0Meta?.name ?? "todo-id",
    path: _91id_93YjTnfCyYZ0Meta?.path ?? "/todo/:id()",
    meta: _91id_93YjTnfCyYZ0Meta || {},
    alias: _91id_93YjTnfCyYZ0Meta?.alias || [],
    redirect: _91id_93YjTnfCyYZ0Meta?.redirect,
    component: () => import("/vercel/path0/pages/todo/[id].vue").then(m => m.default || m)
  }
]